export default {
    success: 'Done!',
    password_changed: 'Your password has been changed',
    something_went_wrong: "Oops..Something went wrong",
    message_seen: 'Read',
    pin: 'Pin',
    type_story: 'Story',
    type_audio: 'Audio',
    slide_to_cancel: 'Slide to cancel',
}
