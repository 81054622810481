export default {
    // https://noodzly.com/app/home
    app: {
        home: {
            subscribed: "Подписан",
            following: "Подписки",
            discover: "Обнаружить",
        },
    },
    user: {
        setting: {
            main: {
                // https://noodzly.com/user/setting/main
                title: "Настройки",
                delete_account: "Удалить аккаунт",
                logout: "Выйти",
            },
            language: {
                // https://noodzly.com/user/setting/language
                title: "Язык",
                button: "Готово",
            },
            password: {
                // https://noodzly.com/user/setting/password
                title: "Пароль",
                old_password: "Старый пароль",
                new_password: "Новый пароль",
                confirm_password: "Подтвердите пароль",
                update: "Обновить",
            },
            email: {
                // https://noodzly.com/user/setting/email
                title: "Электронная почта",
                email: "Электронная почта",
                example: "example@example.com",
                update: "Обновить",
            },
            // https://noodzly.com/user/setting/notification
            notification: {
                title: "Уведомления",
                app: "Приложение",
                follow: "Подписка",
                new_subscriber: "Новый подписчик",
                messages: "Сообщения",
                email: "Электронная почта",
                fund_sent: "Отправленные средства",
                tips_received: "Полученные чаевые",
                save: "Сохранить",
            },
            // https://noodzly.com/user/setting/main
            blacklist: {
                title: "Заблокированные пользователи",
            },
            // https://noodzly.com/user/setting/block-regions
            block_regions: {
                title: "Заблокировать регионы",
            },
            // https://noodzly.com/user/setting/sessions
            sessions: {
                title: "Сеансы",
                something_went_wrong: "Что-то пошло не так?",
                change_your_password: "Изменить пароль",
                device: "Устройство",
                browser: "Браузер",
                date: "Дата",
            },
            // https://noodzly.com/user/setting/contact
            contact: {
                title: "Свяжитесь с нами",
                placeholder: "Что нового?",
                send: "Отправить",
            },
            // https://noodzly.com/user/setting/creator
            creator: {
                title: "Noodzler",
                verify: {
                    title: "Подтверждение аккаунта",
                },
                agreement: {
                    title: "Договор о соглашении",
                    sub_title:
                        "СТАНДАРТНОЕ СОГЛАШЕНИЕ МЕЖДУ NOODZLY И NOODZLER",
                    field: {
                        name: "Имя",
                    },
                    btn: {
                        accept: "принять",
                    },
                },
                // https://noodzly.com/user/setting/creator/verify-ondato
                verify_ondato: {
                    title: "Подтвердить аккаунт с помощью Ondato",
                    verify_account: "Подтвердить аккаунт",
                    action_link: "начать верификацию",
                    add_another_model: "Добавить еще одного Noodzler",
                    model_has_verified: "Noodzler {name} был подтвержден",
                    model_not_verified: "Noodzler {name} не подтвержден",
                    status_created: " ",
                    status_processed: "обработано",
                    status_resolved: "решено",
                    status_rejected: "отклонено",
                },
                withdraw_options: {
                    title: "Опции вывода средств",
                    withdraw: {
                        title: "Вывести",
                        bank_transfer: {
                            title: "Банковский перевод",
                            swift_code: "SWIFT-код",
                            placeholder_swift_code: "Введите SWIFT-код",
                            iban_code: "IBAN-код",
                            placeholder_iban_code: "Введите IBAN-код",
                            bank_name: "Название банка",
                            placeholder_bank_name: "Введите название банка",
                            bank_address: "Адрес банка",
                            placeholder_bank_address: "Введите адрес банка",
                            bank_city: "Город банка",
                            placeholder_bank_city:
                                "Введите название города банка",
                            bank_country: "Страна банка",
                            placeholder_bank_country: "Страна",
                            name_surname: "Ваше имя и фамилия",
                            placeholder_name_surname: "Имя и фамилия",
                            address: "Адрес",
                            placeholder_address: "Ваш адрес",
                            city: "Ваш город",
                            placeholder_city: "Название города",
                            country: "Ваша страна",
                            placeholder_country: "Страна",
                            note_1: "Время получения платежа: Sepa & Swift-платежи обрабатываются ежедневно (понедельник - пятница). Запросы на средства, поданные до 23:59 EST, будут обработаны на следующий рабочий день. Пожалуйста, дайте дополнительные 5 - 7 рабочих дней для зачисления средств на ваш счет.",
                            note_2: "ПРИМЕЧАНИЕ: Ваш банк (или посреднический банк) может взимать с вас сборы в дополнение к комиссии. Сборы, взимаемые вашим банком (или посредническим банком), не находятся под нашим контролем. Пожалуйста, проконсультируйтесь с вашим банком перед запросом платежа, если у вас есть сомнения.",
                        },
                        bitsafe: {
                            title: "Bitsafe",
                            full_name: "Полное имя получателя",
                            iban: "IBAN-код BitSafe",
                        },
                    },
                },
                // https://noodzly.com/user/setting/creator/streams
                streams: {
                    title: "Приватные стримы",
                    tabs: {
                        now: "Сейчас",
                        later: "Позже",
                    },
                    fields: {
                        price: {
                            title: "Цена",
                        },
                        ticketsSold: {
                            title: "Билетов продано",
                        },
                        viewersLimit: {
                            title: "Лимит зрителей",
                        },
                        dateTime: {
                            title: "Начинается в",
                        },
                        isFreeForSubscribers: {
                            title: "Бесплатно для подписчиков",
                        },
                        rating: {
                            title: "Оценка ваших стримов",
                        },
                    },
                    actions: {
                        schedule: "Запланировать",
                        save: "Сохранить",
                        goLive: "Начать трансляцию",
                    },
                    rules: {
                        title: "Используя живые выступления, вы соглашаетесь с нашими ",
                        name: "правила трансляции",
                    },
                },
                stream: {
                    title: "Приватный стрим",
                    streamIsNotLive:
                        "Трансляция еще не началась в прямом эфире",
                    messages: {
                        system: {
                            joined: "присоединился",
                        },
                    },
                    liveReport: {
                        title: "Прямой Эфир",
                        totalTips: "Всего Получено Чаевых",
                        totalTickets: "Всего Продано Билетов",
                        overall: "Итого",
                    },
                    liveEnded: {
                        title: "Трансляция Закончилась",
                        rate: {
                            options: {
                                bad: "Плохо",
                                ok: "Нормально",
                                good: "Хорошо",
                                loveIt: "Обожаю",
                            },
                        },
                    },
                    rules: {
                        title: "Используя прямые трансляции, вы соглашаетесь с нашими ",
                        name: "правилами трансляции",
                    },
                },
                // https://noodzly.com/user/setting/creator/subscription
                subscriptions: {
                    title: "Подписки",
                    subscription_price: "Цена подписки",
                    per_month: "В месяц.",
                    welcome_message: "Приветственное сообщение",
                    welcome_message_rule:
                        "приветственное сообщение будет отправлено автоматически новым подписчикам",
                    save: "Сохранить",
                    subscribers: "Подписчики",
                    saved: "Готово!",
                },
                // https://noodzly.com/user/setting/creator/tips
                tip_menu: {
                    title: "Меню советов",
                    sub_title_1:
                        "Добавьте товары или услуги на продажу. Покупатели могут приобретать их непосредственно с вашего профиля.",
                    sub_title_2:
                        "У вас будет 3 дня на выполнение запроса от покупателя.",
                    enter_name: "Добавить название товара",
                    save: "Сохранить",
                    saved: "Готово!",
                    zero_price: "If you leave the price as 0$, it will be shown as 'On Request'",
                    on_request: "On request",
                },
                // https://noodzly.com/user/setting/creator/messages
                messages: {
                    title: "Сообщения",
                    direct_messages_price: "Цена прямых сообщений",
                    placeholder: "0-49.99",
                    save: "СОХРАНИТЬ",
                    saved: "Готово!",
                    price_suffix: "/Месяц",
                    direct_message_info: "Пользователь, который не хочет подписываться, может отправить вам сообщение, заплатив эту цену."
                },
                referrals: {
                    title: "Рефералы",
                    description:
                        "За каждого, кто зарегистрируется на Noodzly, используя ваш реферальный код, вы получите бонус в размере 3% от их чистого дохода на протяжении 12 месяцев. Этот бонус выплачивается вам в том же времени, что и они выводят свой доход",
                    code: "Код",
                    links: "Ссылки",
                    my_referrals: "Мои рефералы",
                    name: "Имя",
                    status: "Статус",
                    date: "Дата",
                },
                promotion: {
                    title: "Продвижение",
                    description:
                        'Продвижение позволяет вам продвигать свой контент в разделе «Обнаружение» и охватить больше людей, которые могут превратиться в новых подписчиков. Прежде чем начать продвижение, в вашей истории должен быть хотя бы один контент.',
                    notags: "Пожалуйста, выберите хотя бы один тег",
                },
            },
        },
    },
    profile: {
        // https://noodzly.com/profile
        main: {
            creator: "NOODZLER",
            info: "ИНФО",
            followers: "Подписчики",
            following: "Подписки",
            get_app: "Получить приложение",
        },
        // https://noodzly.com/profile/info
        info: {
            title: "Информация",
            type_your_bio: "Ваша биография",
            amazon_url: "Ссылка на Amazon",
            instagram_url: "Ссылка на Instagram",
            twitter_url: "Ссылка на Twitter",
            my_age: "Мой возраст",
            i_am: "Мой пол",
            male: "Мужчина",
            female: "Женщина",
            transgender: "Трансгендер",
        },
        wallet: {
            // https://noodzly.com/profile/wallet
            withdraw: {
                available_balance: "Доступный баланс",
                total_net: "Общий чистый доход",
                total_gross: "Общий валовой доход",
                total_net_info:
                    "30% комиссии используется для оплаты обработки платежей, реферальных выплат, хостинга, поддержки и всех других услуг Noodzly",
                bank_transfer: "Банковский перевод",
                bitsafe: "Bitsafe",
            },
        },
        stream: {
            header: "Трансляции ",
            title: "Следующая Частная Трансляция",
            purchaseTicket: "Купить Билет",
            startWatching: "Начать Просмотр",
        },
        streams: {
            rating: {
                title: "Рейтинг Трансляций",
                stats: {
                    streams: "Трансляции",
                    min: "мин",
                    averageDuration: "Средняя Продолжительность",
                },
            },
        },
    },
    // https://noodzly.com/search
    search: {
        placeholder: "Поиск",
    },
    messages: {
        title: "Сообщения",
        search: "Поиск",
        turn_off_direct_messages: "Отключить прямые сообщения",
    },
    channels: {
        title: "Трансляции",
        yourChannel: "Ваша трансляция",
        description: {
            forEveryone:
                "Широковещательная рассылка - это способ получать сообщения от ваших друзей, чтобы быть в курсе их дальнейших действий!",
            forCreators:
                "Трансляция позволяет вам отправлять сообщения только вашим подписчикам, чтобы держать их в курсе ваших новинок!",
        },
        other_user_broadcast: "{user_name}'s Broadcast",
        pinned_message: "Pinned message",
    },
    email_verify: {
        title: "Подтверждение почты",
        almost_there: "Почти готово!",
        before_link: "Привет {user}, мы отправили подтверждение на",
        change: "(Изменить)",
        after_link: "чтобы начать, подтвердите свою почту.",
        button: "ПОВТОРНО ОТПРАВИТЬ ПИСЬМО",
    },
    privacy: {
        title: "Политика конфиденциальности",
        desc_1: "Используя услуги Noodzly.com, вы соглашаетесь с использованием файлов cookie. Мы используем файлы cookie для аналитики, персонализации и рекламы.",
        desc_2: "На этой странице и на некоторых других на этом сайте устанавливаются и читаются файлы cookie сторонних лиц на вашем браузере, которые используются для необязательных целей. Через эти файлы cookie наши партнеры собирают личные данные о вас для своих собственных целей.",
    },
    home: {
        desktop_description:
            "Noodzly позволяет вам делиться своим любительским контентом полностью анонимно, получая при этом оплату, и весь контент будет удален через 24 часа.",
        qr_code_text: "Отсканируйте QR-код",
        qr_code_sub_text: "Лучше на вашем смартфоне",
    },
    // https://noodzly.com/user/5/tips
    user_tips: {
        title: "Меню советов {name}",
        subtitle: "Товары или услуги, предлагаемые {name}",
        zero_price_text: "You want to request {item_name}?",
        buy_item_text: "You want to buy {tip_name} for {tip_price}$ ?",
    },
};
