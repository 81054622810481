import auth from "./middleware/auth";
import isAdmin from "./middleware/isAdmin";

export default [
    {
        path: '/admin/login',
        name: 'admin-login',
        component: () => import('@/views/admin/auth/Login'),
        meta: { }
    },
    {
        path: '/admin',
        name: 'admin.index',
        component: () => import('../views/admin/Index.vue'),
        meta: { middleware: [auth, isAdmin], layout: 'admin-dashboard'}
    },
    {
        path: '/admin/profile',
        name: 'admin.profile',
        component: () => import('../views/admin/Profile.vue'),
        meta: { middleware: [auth, isAdmin], layout: 'admin-dashboard'}
    },
    {
        path: '/admin/users',
        name: 'admin.users.index',
        component: () => import('../views/admin/users/Index.vue'),
        meta: { middleware: [auth, isAdmin], layout: 'admin-dashboard'}
    },
    {
        path: '/admin/users/:id',
        name: 'admin.users.show',
        component: () => import('../views/admin/users/Show.vue'),
        meta: { middleware: [auth, isAdmin], layout: 'admin-dashboard'}
    },
    {
        path: '/admin/verify-users',
        name: 'admin.verify-users.index',
        component: () => import('../views/admin/verify-users/Index.vue'),
        meta: { middleware: [auth, isAdmin], layout: 'admin-dashboard'}
    },
    {
        path: '/admin/verify-users/:id',
        name: 'admin.verify-users.show',
        component: () => import('../views/admin/verify-users/Show.vue'),
        meta: { middleware: [auth, isAdmin], layout: 'admin-dashboard'}
    },
    {
        path: '/admin/transactions',
        name: 'admin.transactions.index',
        component: () => import('../views/admin/transactions/Index.vue'),
        meta: { middleware: [auth, isAdmin], layout: 'admin-dashboard'}
    },
    {
        path: '/admin/transactions/:id',
        name: 'admin.transactions.show',
        component: () => import('../views/admin/transactions/Show.vue'),
        meta: { middleware: [auth, isAdmin], layout: 'admin-dashboard'}
    },
    {
        path: '/admin/bank-transfers',
        name: 'admin.bank-transfers.index',
        component: () => import('../views/admin/bank-transfers/Index.vue'),
        meta: { middleware: [auth, isAdmin], layout: 'admin-dashboard'}
    },
    {
        path: '/admin/bank-transfers/:id',
        name: 'admin.bank-transfers.show',
        component: () => import('../views/admin/bank-transfers/Show.vue'),
        meta: { middleware: [auth, isAdmin], layout: 'admin-dashboard'}
    },
    {
        path: '/admin/withdrawals/bank-transfer',
        name: 'admin.withdrawals.bank-transfer.index',
        component: () => import('../views/admin/withdrawals/bank-transfer/Index'),
        meta: { middleware: [auth, isAdmin], layout: 'admin-dashboard'}
    },
    {
        path: '/admin/withdrawals/bank-transfer/:id',
        name: 'admin.withdrawals.bank-transfer.show',
        component: () => import('../views/admin/withdrawals/bank-transfer/Show'),
        meta: { middleware: [auth, isAdmin], layout: 'admin-dashboard'}
    },
    {
        path: '/admin/withdrawals/bitsafe',
        name: 'admin.withdrawals.bitsafe.index',
        component: () => import('../views/admin/withdrawals/bitsafe/Index'),
        meta: { middleware: [auth, isAdmin], layout: 'admin-dashboard'}
    },
    {
        path: '/admin/withdrawals/bitsafe/:id',
        name: 'admin.withdrawals.bitsafe.show',
        component: () => import('../views/admin/withdrawals/bitsafe/Show'),
        meta: { middleware: [auth, isAdmin], layout: 'admin-dashboard'}
    },
    {
        path: '/admin/withdrawals/history',
        name: 'admin.withdrawals.history.index',
        component: () => import('../views/admin/withdrawals/history/Index'),
        meta: { middleware: [auth, isAdmin], layout: 'admin-dashboard'}
    },
    {
        path: '/admin/creators',
        name: 'admin.creators.index',
        component: () => import('../views/admin/creators/Index.vue'),
        meta: { middleware: [auth, isAdmin], layout: 'admin-dashboard'}
    },
    {
        path: '/admin/creators/:id',
        name: 'admin.creators.show',
        component: () => import('../views/admin/creators/Show.vue'),
        meta: { middleware: [auth, isAdmin], layout: 'admin-dashboard'}
    },
    {
        path: '/admin/user-reports',
        name: 'admin.user-reports.index',
        component: () => import('../views/admin/user-reports/Index.vue'),
        meta: { middleware: [auth, isAdmin], layout: 'admin-dashboard'}
    },
    {
        path: '/admin/user-reports/:id',
        name: 'admin.user-reports.show',
        component: () => import('../views/admin/user-reports/Show.vue'),
        meta: { middleware: [auth, isAdmin], layout: 'admin-dashboard'}
    },
    {
        path: '/admin/story-reports',
        name: 'admin.story-reports.index',
        component: () => import('../views/admin/story-reports/Index.vue'),
        meta: { middleware: [auth, isAdmin], layout: 'admin-dashboard'}
    },
    {
        path: '/admin/story-reports/:id',
        name: 'admin.story-reports.show',
        component: () => import('../views/admin/story-reports/Show.vue'),
        meta: { middleware: [auth, isAdmin], layout: 'admin-dashboard'}
    },
    {
        path: '/admin/stream-reports',
        name: 'admin.stream-reports.index',
        component: () => import('../views/admin/stream-reports/Index.vue'),
        meta: { middleware: [auth, isAdmin], layout: 'admin-dashboard'}
    },
    {
        path: '/admin/stream-reports/:id',
        name: 'admin.stream-reports.show',
        component: () => import('../views/admin/stream-reports/Show.vue'),
        meta: { middleware: [auth, isAdmin], layout: 'admin-dashboard'}
    },
    {
        path: '/admin/photos',
        name: 'admin.photos.index',
        component: () => import('../views/admin/photos/Index.vue'),
        meta: { middleware: [auth, isAdmin], layout: 'admin-dashboard'}
    },
    {
        path: '/admin/photos/:id',
        name: 'admin.photos.show',
        component: () => import('../views/admin/photos/Show.vue'),
        meta: { middleware: [auth, isAdmin], layout: 'admin-dashboard'}
    },
    {
        path: '/admin/videos',
        name: 'admin.videos.index',
        component: () => import('../views/admin/videos/Index.vue'),
        meta: { middleware: [auth, isAdmin], layout: 'admin-dashboard'}
    },
    {
        path: '/admin/videos/:id',
        name: 'admin.videos.show',
        component: () => import('../views/admin/videos/Show.vue'),
        meta: { middleware: [auth, isAdmin], layout: 'admin-dashboard'}
    },
    {
        path: '/admin/tags',
        name: 'admin.tags.index',
        component: () => import('../views/admin/tags/Index.vue'),
        meta: { middleware: [auth, isAdmin], layout: 'admin-dashboard'}
    },
    {
        path: '/admin/tags/:id',
        name: 'admin.tags.show',
        component: () => import('../views/admin/tags/Show.vue'),
        meta: { middleware: [auth, isAdmin], layout: 'admin-dashboard'}
    },
    {
        path: '/admin/prohibited-words',
        name: 'admin.prohibited_words.index',
        component: () => import('../views/admin/prohibited-words/Index.vue'),
        meta: { middleware: [auth, isAdmin], layout: 'admin-dashboard'}
    },
    {
        path: '/admin/prohibited-words/:id',
        name: 'admin.prohibited_words.show',
        component: () => import('../views/admin/prohibited-words/Show.vue'),
        meta: { middleware: [auth, isAdmin], layout: 'admin-dashboard'}
    },
    {
        path: '/admin/countries',
        name: 'admin.countries.index',
        component: () => import('../views/admin/countries/Index.vue'),
        meta: { middleware: [auth, isAdmin], layout: 'admin-dashboard'}
    },
    {
        path: '/admin/countries/:id',
        name: 'admin.countries.show',
        component: () => import('../views/admin/countries/Show.vue'),
        meta: { middleware: [auth, isAdmin], layout: 'admin-dashboard'}
    },
    {
        path: '/admin/documents-types',
        name: 'admin.documents_types.index',
        component: () => import('../views/admin/documents-types/Index.vue'),
        meta: { middleware: [auth, isAdmin], layout: 'admin-dashboard'}
    },
    {
        path: '/admin/documents-types/:id',
        name: 'admin.documents_types.show',
        component: () => import('../views/admin/documents-types/Show.vue'),
        meta: { middleware: [auth, isAdmin], layout: 'admin-dashboard'}
    },
    {
        path: '/admin/faq-sections',
        name: 'admin.faq_sections.index',
        component: () => import('../views/admin/faq-sections/Index.vue'),
        meta: { middleware: [auth, isAdmin], layout: 'admin-dashboard'}
    },
    {
        path: '/admin/faq-sections/add',
        name: 'admin.faq_sections.add',
        component: () => import('../views/admin/faq-sections/Add.vue'),
        meta: { middleware: [auth, isAdmin], layout: 'admin-dashboard'}
    },
    {
        path: '/admin/faq-sections/:id',
        name: 'admin.faq_sections.show',
        component: () => import('../views/admin/faq-sections/Show.vue'),
        meta: { middleware: [auth, isAdmin], layout: 'admin-dashboard'}
    },
    {
        path: '/admin/pages/sections',
        name: 'admin.pages.sections.index',
        component: () => import('../views/admin/page-sections/Index'),
        meta: { middleware: [auth, isAdmin], layout: 'admin-dashboard'}
    },
    {
        path: '/admin/pages/sections/create',
        name: 'admin.pages.sections.create',
        component: () => import('../views/admin/page-sections/Create'),
        meta: { middleware: [auth, isAdmin], layout: 'admin-dashboard'}
    },
    {
        path: '/admin/pages/sections/:id',
        name: 'admin.pages.sections.edit',
        component: () => import('../views/admin/page-sections/Edit'),
        meta: { middleware: [auth, isAdmin], layout: 'admin-dashboard'}
    },
    {
        path: '/admin/wallet-balances',
        name: 'admin.wallet-balances.index',
        component: () => import('../views/admin/wallet-balances/Index.vue'),
        meta: { middleware: [auth, isAdmin], layout: 'admin-dashboard'}
    },
];
