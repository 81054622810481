export default {
    success: 'Fatto!',
    password_changed: 'La tua password è stata cambiata',
    something_went_wrong: "Ops.. Qualcosa è andato storto",
    message_seen: 'Seen',
    pin: 'Pin',
    type_story: 'Story',
    type_audio: 'Audio',
    slide_to_cancel: 'Slide to cancel',
}
